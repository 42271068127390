<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <contact-list-side-menu :filter="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(c, idx) in entitySummaries"
                :class="{ 'is-selected' : selectedRow === idx }"
                @click="highlightSelected(idx, $event)"
                :key="c.id">
                <td>{{ c.title }}</td>
                <td>
                  <router-link :to="{ path: c.contactId }"
                    append>
                    {{ c.firstName }}, {{ c.lastName }}
                  </router-link>
                </td>
                <td>{{ c.objectType }}</td>
                <td>{{ c.parentName }}</td>
                <td>{{ c.defaultPhone }}</td>
                <td class="is-email">
                  <div>{{ c.defaultEmail }}</div>
                </td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ c.active ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ c.active ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <router-link class="button is-primary is-small is-inverted"
                    :to="{ path: c.contactId }"
                    append>
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </router-link>
                </td>
              </tr>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import ContactService from './ContactService'
import ContactListSideMenu from './ContactListSideMenu'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import Columns from './columns'
import ProtoBuf from 'protobufjs'
import StoreMixin from './storeMixin'
import { ContactPayload } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import _transform from 'lodash/transform'
import { Emailer } from '@/classes'

export default {
  name: 'ContactListView',
  components: {
    AppDetailHeader,
    ContactListSideMenu,
    BulmaTable
  },
  mixins: [StoreMixin],
  data() {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        firstName: '',
        lastName: '',
        contactType: '',
        active: 1,
        sortColumn: Columns[1].name,
        sortOrder: Columns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  async created() {
    await this.getData()
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, data => {
      this.toggle = false
      setTimeout(() => {
        this.toggle = true
      }, 200)
    })
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addStoreItem(new ContactPayload())
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter = {
        firstName: '',
        lastName: '',
        contactType: '',
        active: 1,
        sortColumn: Columns[1].name,
        sortOrder: Columns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      }
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    serialiseFilter() {
      const entityProto = require('../../assets/proto/contact/ContactFilter.proto')
      const filterMessage = entityProto.ContactFilter.ContactFilter
      const buffer = filterMessage.encode(this.filter).finish()
      const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
      return b64encoded
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      this.isTableLoading = true

      this.retrieveFilter()
      const res = await ContactService.getListing(this.serialiseFilter())
      this.entitySummaries = res.data

      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    print() {
      const sortBy = this.filter.sortColumn && this.filter.sortOrder ? `${this.filter.sortColumn} ${this.filter.sortOrder}` : ''

      let hackStatus = this.filter.active
      if (hackStatus === 2) {
        hackStatus = ''
      }

      let params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        FirstName: this.filter.firstName,
        LastName: this.filter.lastName,
        ContactType: this.filter.contactType === '' ? '-1' : this.filter.contactType,
        ActiveStatus: hackStatus,
        SortBy: sortBy
      })
      params = _transform(params, function(result, value, key) {
        result[key] = value
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Contact Profile Table'
      emailer.reportName = 'rptTablesContactProfile'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>
