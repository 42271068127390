import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Title', 'TITLE', true, 'asc', 'left'),
  new TableColumn('First/Last Name', 'firstName,lastName', true, 'asc', 'left'),
  new TableColumn('Contact Type', 'objectType', true, 'asc', 'left'),
  new TableColumn('Contact Name', 'parentName', true, 'asc', 'left'),
  new TableColumn('Phone', 'defaultPhone', true, 'asc', 'left'),
  new TableColumn('Email', 'defaultEmail', true, 'asc', 'left'),
  new TableColumn('Active', 'active', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export default Columns
