/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ContactFilter = (function() {

    /**
     * Namespace ContactFilter.
     * @exports ContactFilter
     * @namespace
     */
    var ContactFilter = {};

    ContactFilter.ContactFilter = (function() {

        /**
         * Properties of a ContactFilter.
         * @memberof ContactFilter
         * @interface IContactFilter
         * @property {string|null} [firstName] ContactFilter firstName
         * @property {string|null} [lastName] ContactFilter lastName
         * @property {number|null} [contactType] ContactFilter contactType
         * @property {number|null} [active] ContactFilter active
         * @property {string|null} [sortColumn] ContactFilter sortColumn
         * @property {string|null} [sortOrder] ContactFilter sortOrder
         * @property {number|null} [pageSize] ContactFilter pageSize
         * @property {number|null} [pageIndex] ContactFilter pageIndex
         */

        /**
         * Constructs a new ContactFilter.
         * @memberof ContactFilter
         * @classdesc Represents a ContactFilter.
         * @implements IContactFilter
         * @constructor
         * @param {ContactFilter.IContactFilter=} [p] Properties to set
         */
        function ContactFilter(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContactFilter firstName.
         * @member {string|null|undefined} firstName
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.firstName = null;

        /**
         * ContactFilter lastName.
         * @member {string|null|undefined} lastName
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.lastName = null;

        /**
         * ContactFilter contactType.
         * @member {number|null|undefined} contactType
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.contactType = null;

        /**
         * ContactFilter active.
         * @member {number|null|undefined} active
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.active = null;

        /**
         * ContactFilter sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.sortColumn = null;

        /**
         * ContactFilter sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.sortOrder = null;

        /**
         * ContactFilter pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.pageSize = null;

        /**
         * ContactFilter pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        ContactFilter.prototype.pageIndex = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ContactFilter _firstName.
         * @member {"firstName"|undefined} _firstName
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_firstName", {
            get: $util.oneOfGetter($oneOfFields = ["firstName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ContactFilter _lastName.
         * @member {"lastName"|undefined} _lastName
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_lastName", {
            get: $util.oneOfGetter($oneOfFields = ["lastName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ContactFilter _contactType.
         * @member {"contactType"|undefined} _contactType
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_contactType", {
            get: $util.oneOfGetter($oneOfFields = ["contactType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ContactFilter _active.
         * @member {"active"|undefined} _active
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_active", {
            get: $util.oneOfGetter($oneOfFields = ["active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ContactFilter _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ContactFilter _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ContactFilter _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ContactFilter _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof ContactFilter.ContactFilter
         * @instance
         */
        Object.defineProperty(ContactFilter.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ContactFilter message. Does not implicitly {@link ContactFilter.ContactFilter.verify|verify} messages.
         * @function encode
         * @memberof ContactFilter.ContactFilter
         * @static
         * @param {ContactFilter.IContactFilter} m ContactFilter message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContactFilter.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.firstName != null && Object.hasOwnProperty.call(m, "firstName"))
                w.uint32(10).string(m.firstName);
            if (m.lastName != null && Object.hasOwnProperty.call(m, "lastName"))
                w.uint32(18).string(m.lastName);
            if (m.contactType != null && Object.hasOwnProperty.call(m, "contactType"))
                w.uint32(24).int32(m.contactType);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(32).int32(m.active);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(56).int32(m.pageSize);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            return w;
        };

        /**
         * Encodes the specified ContactFilter message, length delimited. Does not implicitly {@link ContactFilter.ContactFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ContactFilter.ContactFilter
         * @static
         * @param {ContactFilter.IContactFilter} message ContactFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContactFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ContactFilter message from the specified reader or buffer.
         * @function decode
         * @memberof ContactFilter.ContactFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ContactFilter.ContactFilter} ContactFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContactFilter.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ContactFilter.ContactFilter();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.firstName = r.string();
                    break;
                case 2:
                    m.lastName = r.string();
                    break;
                case 3:
                    m.contactType = r.int32();
                    break;
                case 4:
                    m.active = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageSize = r.int32();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a ContactFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ContactFilter.ContactFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ContactFilter.ContactFilter} ContactFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContactFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return ContactFilter;
    })();

    return ContactFilter;
})();

module.exports = $root;
